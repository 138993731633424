<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol col="6">
                <CInput
                    label="Açıklama"
                    placeholder="Açıklama"
                    type="text"
                    v-model="data.description"
                    horizontal
                    required
                    pattern="[^ığüşöçĞÜŞÖÇİ]+" 
                />
            </CCol>
            <CCol col="6">
                <CInput
                    label="IP Adresi"
                    placeholder="IP Adresi"
                    type="text"
                    v-model="data.ipAddress"
                    horizontal
                    required
                    pattern="[^ığüşöçĞÜŞÖÇİ]+" 
                />
            </CCol>
        </CRow>
        <CRow>
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Blok</b>
            </CCol>
            <CCol sm="3">
                <CSwitch
                size="lg"
                color="primary"
                :checked="data.blockFlag"
                v-model="data.blockFlag"
                @update:checked="setSwitch('blockFlag')"
                />
            </CCol>
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "IpForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {}
            }
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
        methods:{
            setSwitch(data) {
                this.data[data] = !this.data[data]
            },
        }
    }
</script>